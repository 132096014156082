'use strict';

/**
 * @description Custom layout for product variant select2 selection options.
 * Makes non-orderable options to have specific class, so they can be styled accordingly
 * @param state
 * @return {jQuery|HTMLElement}
 */
function renderProductVariantSelectionOption(state) {
    if (!state.id || !state.element) {
        return state.text;
    }

    return $(`
        <div
            class="select2-results__option-inner"
            data-orderable="${state.element.dataset.orderable}"
        >
                ${state.text}
        </div>
    `);
}

var selectDropDown = {
    /**
     * @function
     * @description Init select2 for select drop downs
    **/
    customSelects: function($selectElements) {
        $selectElements.each(function() {
            var $this = $(this);
            // BUG of Select2 lib specific version, see https://github.com/select2/select2/issues/5320
            var select2Config = {};

            if ($this.data('placeholder')) {
                select2Config.placeholder = {
                    id: 'empty-select-value',
                    text: $this.data('placeholder')
                };
            }

            if ($this.hasClass('variation-select')) {
                select2Config.templateSelection = renderProductVariantSelectionOption;
                select2Config.templateResult = renderProductVariantSelectionOption;
            }

            $this.select2(select2Config);
        });
    },

    customSelectsContactUs: function() {
        var selectInterval = setInterval(function() {
            var $customerServices = $('.formstack-form .ff-select-type');

            if ($customerServices.length) {
                $customerServices.select2();
                $customerServices.parent().addClass('select2--secondary');
                clearInterval(selectInterval);
            }
        }, 200);
    },

    init: function() {
        this.customSelects($('.js-select-dropdown, .js-custom-dropdown .input-select'));
        this.customSelectsContactUs();
    }
};

selectDropDown.init();

module.exports = selectDropDown;
